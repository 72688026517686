.okg-icon {
  &--copy {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('../asserts/images/icons/copy.svg');
  }
}

[disabled] {
  .okg-icon {
    background-image: none;
    background-color: $default-text-color;
    &--copy {
      mask-image: url('../asserts/images/icons/copy.svg');
    }
  }
}
